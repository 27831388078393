'use client'

import { useState, KeyboardEvent } from 'react'
import { useIntl } from 'react-intl'

import { onA11yKeyDown } from 'data/utils/a11y'
import useTracking from 'hooks/useTracking'
import ItemPriceBreakdownModal from 'components/ItemPriceBreakdownModal'
import DetailedItemPriceBreakdownModal from 'components/DetailedItemPriceBreakdownModal'
import { clickEvent } from 'libs/common/event-tracker/events'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { EscrowFeesModel } from 'types/models/escrow-fees'
import { CurrencyAmountModel } from 'types/models'

import InlinePrice from './InlinePrice'
import NonInlinePrice from './NonInlinePrice'

type Props = {
  itemId: number
  isItemOwner?: boolean
  itemPrice: CurrencyAmountModel
  isSellerBusiness: boolean
  totalItemPrice?: CurrencyAmountModel | null
  serviceFee?: CurrencyAmountModel | null
  shouldTrimTitle?: boolean
  shouldMinimiseTitle?: boolean
  onModalOpen?: () => void
  onModalClose?: () => void
  detailedBreakdown?: {
    itemPhotoSrc?: string | null
    escrowFees?: EscrowFeesModel
    itemTitle: string
    transactionId?: number
  } | null
  showInclinePrice?: boolean
}

const ServiceFeeIncludedTitle = ({
  itemId,
  totalItemPrice,
  itemPrice,
  serviceFee,
  isItemOwner,
  isSellerBusiness,
  shouldTrimTitle,
  shouldMinimiseTitle,
  onModalOpen,
  onModalClose,
  detailedBreakdown,
  showInclinePrice = true,
}: Props) => {
  const [isPriceBreakdownModalOpen, setIsPriceBreakdownModalOpen] = useState(false)

  const { track } = useTracking()
  const { locale } = useIntl()

  const formattedItemPrice = formatCurrencyAmount(itemPrice, locale)
  const formattedServiceFee = serviceFee?.amount ? formatCurrencyAmount(serviceFee, locale) : null

  const handleClick = () => {
    track(
      clickEvent({
        target: ClickableElement.PricingDetails,
        targetDetails: JSON.stringify({ item_id: itemId }),
      }),
    )

    if (isPriceBreakdownModalOpen) onModalClose?.()
    else onModalOpen?.()

    setIsPriceBreakdownModalOpen(prevState => !prevState)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    onA11yKeyDown(event, handleClick)
  }

  if (!totalItemPrice?.amount) return null

  const priceProps = {
    onKeyDown: handleKeyDown,
    onClick: handleClick,
    isSellerBusiness,
    totalItemPrice,
    isItemOwner,
    shouldMinimiseTitle,
    shouldTrimTitle,
  }

  const commonBreakdownProps = {
    isVisible: isPriceBreakdownModalOpen,
    itemPrice: formattedItemPrice,
    onClose: handleClick,
    isSellerBusiness,
    isItemOwner: !!isItemOwner,
    itemId,
  }

  return (
    <>
      {showInclinePrice ? <InlinePrice {...priceProps} /> : <NonInlinePrice {...priceProps} />}
      {detailedBreakdown ? (
        <DetailedItemPriceBreakdownModal {...commonBreakdownProps} {...detailedBreakdown} />
      ) : (
        <ItemPriceBreakdownModal serviceFee={formattedServiceFee} {...commonBreakdownProps} />
      )}
    </>
  )
}

export default ServiceFeeIncludedTitle
