'use client'

import { useState } from 'react'
import { Icon } from '@vinted/web-ui'
import { AuthenticityDiamond32 } from '@vinted/multichrome-icons'
import { InfoCircle16 } from '@vinted/monochrome-icons'
import { useIntl } from 'react-intl'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import { clickEvent } from 'libs/common/event-tracker/events'
import { EscrowFeeItemVerificationModel } from 'types/models/escrow-fees'
import OfflineVerificationBuyerModal from 'components/OfflineVerificationBuyerModal'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import FeeDetails from '../FeeDetails'

type Props = {
  itemVerification: EscrowFeeItemVerificationModel
  isItemOwner: boolean
  itemId: number
}

const ItemVerificationFee = ({ itemVerification, isItemOwner, itemId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const translate = useTranslate('item_price_breakdown_detailed')
  const { locale } = useIntl()
  const { track } = useTracking()

  if (!itemVerification) return null

  const handelInfoIconClick = () => {
    setIsModalOpen(true)

    track(
      clickEvent({
        screen: Screen.PriceBreakdownModal,
        target: ClickableElement.ItemVerificationInfo,
        targetDetails: JSON.stringify({
          item_id: itemId,
        }),
      }),
    )
  }

  const renderInfoIcon = () => (
    <>
      <button
        className="u-flexbox u-align-items-center u-cursor-pointer"
        onClick={handelInfoIconClick}
        type="button"
      >
        <Icon
          color={Icon.Color.GreyscaleLevel4}
          name={InfoCircle16}
          testId="item-verification-info-icon"
        />
      </button>
      <OfflineVerificationBuyerModal
        show={isModalOpen}
        verificationFee={formatCurrencyAmount(itemVerification.finalPrice, locale)}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  )

  return (
    <FeeDetails
      testId="item-price-breakdown-item-verification-cell"
      feeTitle={translate('item_verification')}
      escrowFee={itemVerification}
      prefix={
        <Icon
          name={AuthenticityDiamond32}
          aria={{
            'aria-hidden': 'true',
          }}
        />
      }
      infoIcon={renderInfoIcon()}
      isItemOwner={isItemOwner}
      noteText={translate('optional')}
    />
  )
}

export default ItemVerificationFee
